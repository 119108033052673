import {
  Appear,
  Notes,
  Head,
  Image,
  FullScreenCode,
  Horizontal,
  Invert,
  Split,
  SplitRight,
} from '..'

export default {
  Appear,
  Notes,
  Head,
  Image,
  FullScreenCode,
  Horizontal,
  Invert,
  Split,
  SplitRight,
}
